.login-container {
  .ant-form-item{
    margin-bottom: 8px !important;
  }
}
.empty-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}
.form {
  min-height: 60vh;
  margin-top: -30px;
}
.form-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.form-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input {
  padding: 7 !important;
  font-size: 18 !important;
  margin: 10px 0px !important;
  background: #ffffff !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  max-width: 80vw;
}
.login-btn {
  margin-top: 20px !important;
  width: 250px !important;
  height: 46px !important  ;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #FAD400 !important;
  border: 1px solid var(--border-color) !important;
  box-sizing: border-box !important;
  border-radius: 15px !important;
  color: #fff !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center !important;
  width: 100% !important;
}
.form-footer {
  margin-top: 15px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  text-align: center !important;
}

.check-box {
  margin: 25px !important;
}

.login {
  &__input {
    padding-left: 35px !important;
    &__icon {
      position: relative;
      .iconLeftInput {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        left: 10px;
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
      .eyeIcon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        right: 10px;
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }
}

.style_login {
  padding: 0 20px !important;
  label {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .ant-form-item-extra {
    color: red;
  }
}

.style_buton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_signup {
  margin-bottom: 20px;
}
.extra {
  color: #FAD400;
  font-size: 16px;
  text-align: right;
  text-decoration: underline;
}
