.body {
    display: flex !important;
    justify-items: center !important;
    text-align: center !important;
    padding: 20px;
}

.body-title {
    font-size: 25px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.body-des {
    font-size: 16px !important;

}

.zalo-container {
    text-align: center;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.zalo {
    background: 10px 10px 10 rgb(0 0 0 /0.2) !important;
    width: 60% !important;

}

.footer {
    display: flex;
    justify-content: center;
    padding: 0 16px;
}

.back-btn {
    border: 1px var(--border-color) !important;
    background: #FAD400 !important;
    border-radius: 12px !important;
    height: 50px !important;
    color: white !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    width: 100%;
}

.header {
    padding: 0px;
}