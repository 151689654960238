.overall-container {}

.arrow-icon {
    font-size: 20px !important;
    color: white !important;
}

.header-title {
    font-size: 20px;
    font-weight: 700;
    color: white !important;
}

.loading {
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.07);
    position: absolute;
}

.image-container {
    margin: 10px 0px;
    height: 180px;
    background: #eee;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #eee;
}

.user-img-container {
    width: 100% !important;
    padding: 0px 20px !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
}

.information-icon {
    font-size: 25px !important;
    color: #777 !important;
    margin-right: 10px !important;
}

.confirm-btn {
    width: 50% !important;
}

.personal-information-container {
    width: 100% !important;
    padding: 10px 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.information-form {}

.information-input-form {
    border-radius: 5px !important;
    padding: 5px !important;
    font-size: 18px !important;
    width: 100% !important;
}

.bank-form-container {
    width: 100%;
    padding: 10px 20px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.bank-input {
    border-radius: 5px !important;
    font-size: 18px !important;
    margin: 10px 0px !important;
}

.mask-input-date {
    border-radius: 5px !important;
    padding: 7px !important;
    font-size: 16px !important;
    width: 100% !important;
    border: 1px solid #eaeaea !important;
}

.select-bank {
    width: 90vw !important;
    margin: 10px 0px !important;
    border-radius: 30px !important;
    font-size: 18px !important;

    .ant-select-selector {
        height: fit-content !important;
        min-height: 40px !important ;
        display: flex;
        align-items: center;
    }
}

.confirm-div {
    background: #FAD400 !important;
    align-items: center !important;
    padding: 10px 20px !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: 12px !important;
    margin-top: 10px;
}

.send-request-div {
    align-items: center !important;
    padding: 10px 0px !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: 30px !important;
}

.confirm-btn {
    align-content: center !important;
    text-align: center !important;
    height: 58px !important;
    background: #FAD400 !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 30px !important;
}

.confirm-div-title {
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 19px !important;
}

.myInfo {
    background-color: white;

    .myInfoItem {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 0 10px;
        padding: 12px 0 16px 0;
        border-bottom: 1px dotted #000;
        align-items: center;

        p {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

}

.d-flex {
    display: flex;
}

.align-item-center {
    align-items: center;
}

.noteUser {
    padding: 10px;
    font-size: 14px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    height: 58px !important;
    background: #FAD400 ;
    border: 1px solid var(--border-color) ;
    border-radius: 12px !important;
    color: white;
    margin: 0 10px;
    width: auto;
}