body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  --main-bg-color: #FAD400;
  --cancel-btn-colr: #CE4F53;
  --border-color: #FAD400;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mask-focus {
  outline: none;
}

/* // #006997
// #008f50
// #ff7323 */
.head {
  background: #FAD400;
  padding: 10px 0px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  padding: 35px 15px 10px;
  border-bottom: 1px solid #eee;
  background: #FAD400;
  color: white !important;
}

.left-icon {
  font-size: 25px !important;
  color: white !important;
}

.mask-focus:focus {
  border: 1.2px solid rgb(71, 136, 219) !important;
}


.image {
  -webkit-animation: spin 0.8s linear;
  -moz-animation: spin 0.8s linear;
  animation: spin 0.8s linear;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotateX(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotateX(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
}

.disabled_custom {
  pointer-events: none !important;
  background-color: lightgray !important;
  color: gray !important;
  border: 0px solid #CECECE !important;
  border-radius: 12px !important;
}
